import React from "react"
import styled from "styled-components"
import { useStaticQuery, graphql } from "gatsby"
import Img from "gatsby-image"
import Layout from "../components/common/layout/layout"
import SEO from "../components/common/layout/seo"

const NotFoundPage = ({ location }) => {
  const data = useStaticQuery(graphql`
    query {
      file(sourceInstanceName: { eq: "product" }, name: { eq: "404" }) {
        childImageSharp {
          fluid(maxWidth: 627) {
            originalName
            src
            srcSet
            aspectRatio
            sizes
          }
        }
      }
    }
  `)

  return (
    <Layout {...{ location }}>
      <SEO title="404: Not found" />
      <NotFoundPageWrapper>
        <StyledImage fluid={data.file.childImageSharp.fluid} />
      </NotFoundPageWrapper>
    </Layout>
  )
}

const NotFoundPageWrapper = styled.div`
  margin: 140px;
  display: flex;
  justify-content: center;

  @media (max-width: ${props => props.theme.screen.sm}) {
    margin: 140px 20px;
  }
`
const StyledImage = styled(Img)`
  width: 60%;

  @media (max-width: ${props => props.theme.screen.md}) {
    width: 100%;
  }
`

export default NotFoundPage
